<template>
    <Teleport to="body">
        <transition name="fade">
            <div v-if="isOpen" class="modal-overlay" @click.self="close">
                <div class="modal-content">
                    <!-- TODO: Replace below icon -->
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute size-5 top-[15px] left-8 fill-slate-400"
                        viewBox="0 0 256 256"
                    >
                        <path
                            d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"
                        ></path>
                    </svg>
                    <button class="close-button" @click="close">×</button>
                    <input
                        v-model="searchQuery"
                        ref="searchInput"
                        @input="handleSearch"
                        type="text"
                        placeholder="Search..."
                        class="search-input"
                    />
                    <div class="bg-green-700 h-1 search-anim" v-if="isSearching"></div>

                    <div class="search-results">
                        <div v-if="searchResults.length > 0">
                            <label>{{ searchResults.length }} Results Found</label>
                            <div v-for="result in searchResults" :key="result.name" class="result">
                                {{ result.name }}
                            </div>
                        </div>
                        <p v-else>
                            <span v-if="isSearching">Searching...</span>
                            <span v-else-if="triggerSearch.length > 2">No results found</span>
                            <span v-else>Search for companies</span>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { useSearchModalStore } from '@/stores/useSearchModalStore';
import { debounce } from 'lodash-es';

const nuxtApp = useNuxtApp();
const store = useSearchModalStore();
const isOpen = ref(store.isOpen);
const searchQuery = ref('');
const searchInput = ref(null);
const triggerSearch = ref('');
const isSearching = ref(false);

const searchResults = ref([]);

watch(
    () => store.isOpen,
    async (newVal) => {
        isOpen.value = newVal;
        if (newVal) {
            await nextTick();
            if (searchInput.value) {
                searchInput.value.focus();
            }
        }
    }
);

function close() {
    store.close();
}

async function fetchResults() {
    if (!searchQuery.value) return;
    if (searchQuery.value.length < 3) return;
    if (triggerSearch.value === searchQuery.value) return;

    triggerSearch.value = searchQuery.value;

    isSearching.value = true;
    console.log('Searching for:', searchQuery.value);

    const { data, status } = await usePbFetch(nuxtApp.$api.v3.public.compmanies.search(searchQuery.value, 5));

    console.log(status.value);

    if (status.value == 'success') {
        searchResults.value = data.value.data;
    }

    console.log('Search results:', data);

    isSearching.value = false;
}

function handleSearch() {
    debounce(fetchResults, 2500)();
}

function handleKeyDown(event) {
    if (event.key === 'Escape') {
        close();
    }
}

onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
});
</script>

<style scoped lang="scss">
.modal-overlay {
    @apply fixed top-0 left-0 w-full h-full bg-slate-700/50 flex justify-center items-start z-[998];
}

.modal-content {
    @apply bg-white-700 rounded-md z-[999] relative w-[90%] max-w-[500px] shadow-md mt-20 overflow-hidden;
}

.close-button {
    @apply absolute top-[5px] right-4 bg-none border-none text-slate-700 text-2xl cursor-pointer;
}

.search-input {
    @apply w-full px-16 py-2 h-12 text-base text-slate-700 rounded-t-md border-b border-slate-200 outline-none;
}

.search-results {
    @apply bg-white-700 rounded-b-md p-4;
}

.search-results label {
    @apply pl-4 py-2 block;
}

.result {
    @apply px-4 py-2 text-sm text-slate-600 rounded-md;
    @apply hover:bg-blue-100 cursor-pointer hover:text-slate-700;
}

.result:last-child {
    @apply border-none;
}

.search-results p {
    @apply pl-4 py-2 text-sm text-slate-500;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.search-anim {
    @apply top-0 left-0 h-px bg-green-700 relative w-1/4 rounded-full;
    animation: search 2s infinite;
}

@keyframes search {
    0% {
        left: 0;
    }

    100% {
        @apply w-full;
        left: 100%;
    }
}
</style>
