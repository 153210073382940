import UpsellModal from '~/components/modals/personal/UpsellModal.vue';
import EmailScanDisconnectModal from '~/components/modals/personal/EmailScanDisconnectModal.vue';
import GiftingModal from '~/components/modals/personal/GiftingModal.vue';
import SupportModal from '~/components/modals/personal/SupportModal.vue';
import { useGiftingModalStore } from '~/stores/personal/useGiftingModalStore';

export function useDynamicDialog(name: DialogName, contact?: any) {
    const nuxtApp = useNuxtApp();
    const modalStore = useGiftingModalStore();

    switch (name) {
        case 'personal:upsell':
            return nuxtApp.$dialog.open(UpsellModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:email_scan_disconnect':
            return nuxtApp.$dialog.open(EmailScanDisconnectModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });

        case 'personal:support_form':
            return nuxtApp.$dialog.open(SupportModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });

        case 'personal:gifting':
            if (contact) {
                modalStore.openGiftingModal({
                    name: contact.name,
                    email: contact.email,
                    profilePicture: contact.profile_picture,
                    renewalDate: '12/21/2024',
                    paymentLast4: '7193',
                    amount: 197.0,
                });
            }
            return nuxtApp.$dialog.open(GiftingModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        default:
            throw new Error(`Unknown dialog name: ${name}`);
    }
}
