import type { AuthUser, PbAccessToken } from '~/types/auth/auth';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export const useAuthStore = defineStore('auth', () => {
    const nuxtApp = useNuxtApp();
    const config = useRuntimeConfig();
    const encryption = useEncryption();

    const cookie = nuxtApp.$cookie.get(config.public.pb.authTokenName);

    const user = ref(null as AuthUser | null);

    // Read the cookie value outside the setup function

    const token = ref(cookie.value);

    const loggedIn = computed(() => !!token.value);

    async function getMe() {
        if (user.value) return; //dont get user if already exists

        //same request as above in usefetch
        const { data, error } = await useFetch<AuthUser>(`${config.public.pb.apiDomain}/auth/me`, {
            method: 'GET',
            headers: {
                Authorization: `${token.value}`,
            },
        });

        user.value = data.value;

        if (error.value) {
            Sentry.captureException({ auth_getme_error: error.value });
        }
    }

    async function signIn(fromTrust = false) {
        const state = nuxtApp.$cookie.get('pb-auth-state');
        state.value = encryption.generateRandomString();
        const challenge = await encryption.getChallenge(state.value);

        // get current route
        const route = useRoute();

        const subDomain = nuxtApp.$cookie.get('pb-redirect-subdomain');
        const redirectCookie = nuxtApp.$cookie.get('pb-redirect');

        if (fromTrust) subDomain.value = 'trust';

        redirectCookie.value = route.fullPath;

        window.location.href = `${config.public.pb.authHost}/oauth/authorize?protocol=oauth2&client_id=${config.public.pb.authClientId}&redirect_uri=${config.public.pb.authRedirectUri}&response_type=${config.public.pb.authResponseType}&scope=&state=${state.value}&code_challenge=${challenge}&code_challenge_method=S256&flow=pb`;
    }

    async function setToken(token: PbAccessToken) {
        const _rawTokenCookie = nuxtApp.$cookie.get(config.public.pb.authTokenName, token.expires_in);
        _rawTokenCookie.value = `Bearer ${token.access_token}`;
        const _rawRefreshTokenCookie = nuxtApp.$cookie.get(config.public.pb.refreshTokenName, 10000000);
        _rawRefreshTokenCookie.value = token.refresh_token;
    }

    async function exchangeToken(token: string) {
        const state = nuxtApp.$cookie.get('pb-auth-state');

        try {
            const { data: data } = await axios.post<PbAccessToken>(`${config.public.pb.authHost}/oauth/token`, {
                protocol: 'oauth2',
                grant_type: 'authorization_code',
                client_id: config.public.pb.authClientId,
                redirect_uri: config.public.pb.authRedirectUri,
                code_verifier: state.value,
                code: token,
            });

            await setToken(data);
            state.value = null;

            await getMe();
        } catch (error) {
            console.log({ error });
        }
    }

    async function logout() {
        const router = useRouter();
        const nuxtApp = useNuxtApp();

        //clear cookies
        const cookies = document.cookie;
        const cookieObject = cookies.split('; ').reduce((acc, current) => {
            const [key, value] = current.split('=');
            acc[key] = decodeURIComponent(value);
            return acc;
        }, {});

        Object.keys(cookieObject).forEach((cookieName) => {
            const _rawCookie = nuxtApp.$cookie.get(cookieName);

            console.log({ cookieName, _rawCookie });

            _rawCookie.value = null;
        });

        const { data, error } = await usePbFetch(nuxtApp.$api.v3.me.logout(), {
            method: 'POST',
        });

        if (error.value) {
            Sentry.captureException({ auth_logout_error: error.value });
        }

        // router.push('/auth/sign-in');
        // console.log('logged out');
    }

    return { user, token, loggedIn, getMe, logout, signIn, setToken, exchangeToken };
});
