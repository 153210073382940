<template>
    <div class="personal-nav-container text-white-700" :class="{ 'personal-nav-container-mobile': layout_store.isMobileNavOpen }">
        <div class="logo-container">
            <main-logo-svg />

            <XMarkIcon
                class="h-7 w-7 text-white-700"
                v-if="layout_store.isMobileNavOpen"
                @click="layout_store.toggleMobileNav()"
            />
        </div>

        <div class="switch-container">
            <div class="personal-business-switch">
                <a href="#" class="active-switch">Personal</a>
                <a href="#">Work</a>
            </div>
        </div>

        <div class="nav-container">
            <leftnav-item title="Dashboard" path="/personal/dashboard" :is-protected="true">
                <dashboard-icon class="stroke-white-700" />
            </leftnav-item>

            <leftnav-item title="Active Exposures" path="/personal/active-exposures" :is-protected="true">
                <id-card-icon class="stroke-white-700" />
            </leftnav-item>

            <leftnav-item title="Excruciating Detail" path="/personal/excruciating-detail" :is-protected="true">
                <list-icon class="stroke-white-700"
            /></leftnav-item>

            <leftnav-item title="Trust & Family" path="/" :is-protected="false" :trust-page="true">
                <shield-icon class="stroke-white-700" />
            </leftnav-item>

            <div class="divider"></div>

            <!-- <leftnav-item title="Buzz" path="/personal/buzz" :is-protected="true">
                <bolt-icon class="stroke-white-700" />
            </leftnav-item> -->

            <leftnav-item title="Private Search" path="/personal/private-search" :is-protected="true">
                <search-icon class="stroke-white-700" />
            </leftnav-item>

            <leftnav-item title="Gmail Inbox Scan" path="/personal/inbox-scan" :is-protected="true">
                <envolope-icon class="stroke-white-700" />
            </leftnav-item>

            <div class="divider"></div>

            <leftnav-item title="Manage Account" path="/personal/manage-account" :is-protected="true">
                <user-circle-icon class="stroke-white-700" />
            </leftnav-item>

            <!-- <leftnav-item title="Support" path="/personal/support">
                <support-icon class="stroke-white-700" />
            </leftnav-item>

            <concierge-drawer v-if="person && person.pro" /> -->

            <div class="divider"></div>

            <div class="flex flex-1"></div>

            <!-- <div class="suggestions-box">
                <span class="text-style-link !text-white-700">Suggestions & Next Steps</span>
                <label class="text-white-700">25% Complete</label>
                <PrimeProgressBar
                    :value="25"
                    pt:root="bg-green-400 h-[8px] rounded-[23px]"
                    pt:value="bg-green-700 h-[8px] rounded-[23px]"
                    pt:label="hidden"
                ></PrimeProgressBar>

                <label class="text-white-500 pt-6">Next Step</label>
                <span class="text-style-link !text-white-700 pb-2">Add a phone number</span>
                <div class="flex flex-col gap-2">
                    <pb-button label="Add" type="secondary" />
                    <pb-button label="Skip" type="tertiary" />
                </div>
            </div> -->
        </div>
    </div>
</template>

<script setup>
//logo
import MainLogoSvg from '~/assets/branding/main-logo.svg';

//icons
import IdCardIcon from '~/assets/icons/id-card-icon.svg';
import DashboardIcon from '~/assets/icons/dashboard.svg';
import ListIcon from '~/assets/icons/list.svg';
import ShieldIcon from '~/assets/icons/shield.svg';
import BoltIcon from '~/assets/icons/bolt.svg';
import SearchIcon from '~/assets/icons/search.svg';
import EnvolopeIcon from '~/assets/icons/envelope.svg';
import UserCircleIcon from '~/assets/icons/user-circle.svg';
import SupportIcon from '~/assets/icons/support.svg';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import ConciergeDrawer from './LeftNav/ConciergeDrawer.vue';

//components
import PbButton from '../shared/PbButton.vue';
import LeftnavItem from './LeftNav/LeftnavItem.vue';
import PrimeButton from 'primevue/button';

const layout_store = useLayoutStore();
const { person } = usePersonalStore();
</script>

<style lang="scss" scoped>
.personal-nav-container {
    @apply w-[280px] bg-slate-700 fixed h-full top-0 left-0 z-50 pb-6;

    background-image: url('/images/blocks.svg');
    background-position: bottom;
    background-repeat: no-repeat;
}

.personal-nav-container-mobile {
    @apply w-screen bg-slate-700 fixed h-full top-0 left-0 z-50;
}

.logo-container {
    @apply flex items-center justify-between md:justify-center h-[var(--size-header-height)] px-6;
}

//switch
.personal-business-switch {
    @apply px-6;

    a {
        @apply w-[50%] py-2 text-center rounded-sm inline-block bg-[#000] bg-opacity-50;

        color: rgba(255, 255, 255, 0.5);

        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }

    .active-switch {
        @apply bg-yellow-700 text-slate-700 bg-opacity-100;
        text-shadow: 0px 1px rgba(255, 255, 255, 0.5);

        &:hover {
            @apply text-slate-700;
        }
    }
}

.nav-container {
    height: calc(100vh - var(--size-header-height) * 2);
    @apply pb-6 overflow-y-auto flex flex-col mt-2;

    //edit scrollbar
    &::-webkit-scrollbar {
        width: 8px;
        @apply bg-slate-700;
    }

    &::-webkit-scrollbar-thumb {
        @apply bg-blue-500 rounded-[20px];
    }
}

.divider {
    @apply border-b border-white-200 my-6;
}

//suggestions box
.suggestions-box {
    @apply flex flex-col gap-1 px-6;
}

.switch-container {
    @apply h-[var(--size-header-height)] grid items-center;
}
</style>
