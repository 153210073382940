export default defineNuxtRouteMiddleware(async (to, from) => {
    const auth = useAuthStore();
    const personal_store = usePersonalStore();

    if (to.name !== 'auth-callback' && to.name !== 'Sign In') {
        await auth.getMe();
        await personal_store.getPerson();
    }

    if (to.path === '/' && to.meta.pageGroup !== 'trust') {
        return (to.path = '/personal/dashboard');
    }

    if (!auth.loggedIn && to.name != 'Sign In' && to.name != 'auth-callback' && to.meta.pageGroup !== 'trust') {
        return (to.path = '/auth/sign-in');
    }
});
