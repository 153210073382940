import iconfield from './iconfield';
import button from './button';
import tablist from './tablist';
import tab from './tab';
import radiobutton from './radiobutton';
import checkbox from './checkbox';
import toggleswitch from './toggleswitch';
import textarea from './textarea';
import inputtext from './inputtext';
import DataTable from './datatable';
import Column from './column';
import Paginator from './paginator';
import select from './select';
import skeleton from './skeleton';
import datepicker from './datepicker';
import confirmdialog from './confirmdialog';

export default {
    confirmdialog,
    iconfield,
    button,
    tablist,
    tab,
    radiobutton,
    checkbox,
    toggleswitch,
    textarea,
    inputtext,
    DataTable,
    Column,
    Paginator,
    select,
    skeleton,
    datepicker,
};
