<template>
    <div class="bg-white-700 p-9 rounded max-w-[600px]">
        <p class="dialog-title">Submit a Ticket</p>
        <p class="dialog-desc">
            Need assistance? Fill out the form below to get support from our team. Provide as much detail as possible about your
            issue or question to help us address your needs quickly and effectively.
        </p>

        <prime-textarea
            placeholder="Provide details about your issue or question"
            rows="5"
            class="w-full bg-slate-200"
            v-model="request_message"
        ></prime-textarea>

        <div class="flex justify-between items-center mt-3">
            <prime-button label="Cancel" variant="link" @click="dialogRef.close()" />
            <prime-button label="Submit" :loading="isSendingMessage" @click="sendMessage()" />
        </div>
    </div>
</template>

<script setup>
import PrimeTextarea from 'primevue/textarea';
import PrimeButton from 'primevue/button';
import { useToast } from 'vue-toastification';

const parser = new UAParser();

import { inject } from 'vue';
import { UAParser } from 'ua-parser-js';

const dialogRef = inject('dialogRef');
const auth = useAuthStore();

const request_message = ref('');
const isSendingMessage = ref(false);

async function sendMessage() {
    isSendingMessage.value = true;
    const deviceInfo = parser.getResult();

    const formData = new FormData();
    formData.append('first_name', auth.user.first_name);
    formData.append('last_name', auth.user.last_name);
    formData.append('work_email', auth.user.emails[0].email);
    formData.append('subject', 'TEST #3');
    formData.append('message', request_message.value);
    formData.append('browser', deviceInfo.browser.name);
    formData.append('os', deviceInfo.os.name);
    formData.append('ua', deviceInfo.ua);

    const { data, error } = useFetch('https://hooks.zapier.com/hooks/catch/10419791/3wftnb2/', {
        method: 'POST',

        //Form data
        body: formData,
    });

    if (error.value) {
        toast.error('Failed to send message. Please try again later.');
        isSendingMessage.value = false;
        return console.error(error.value);
    }

    isSendingMessage.value = false;

    const toast = useToast();
    toast.success('Message sent successfully!');

    dialogRef.value.close();
}
</script>

<style lang="scss" scoped>
.dialog-title {
    color: var(--slate-600, rgba(2, 6, 23, 0.75));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @apply mb-2;
}

.dialog-desc {
    color: rgba(2, 6, 23, 0.75);

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    @apply mb-6;
}
</style>
