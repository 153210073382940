import { default as callbackNluM3LZzKGMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/auth/callback.vue?macro=true";
import { default as sign_45inTO1jpd5EAxMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/auth/sign-in.vue?macro=true";
import { default as indexKX1Fy9jsK7Meta } from "/home/runner/work/hive-ui/hive-ui/pages/main/business/[slug]/index.vue?macro=true";
import { default as compskOOPNwDIyPMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/comps.vue?macro=true";
import { default as indexSSjIyB5kNfMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/index.vue?macro=true";
import { default as active_45exposures5Ssfqtoe1OMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/active-exposures.vue?macro=true";
import { default as dashboardvymvf8DBDJMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/dashboard.vue?macro=true";
import { default as excruciating_45detailok7Jr4EhMiMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/excruciating-detail.vue?macro=true";
import { default as inbox_45scan46nSuBmEzyMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/inbox-scan.vue?macro=true";
import { default as manage_45accountE1vCWQLUG9Meta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/manage-account.vue?macro=true";
import { default as private_45searchBTlSV3lN7ZMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/private-search.vue?macro=true";
import { default as supportRY1ejdjsRhMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/support.vue?macro=true";
import { default as _91slug_93n2ApAA9AHyMeta } from "/home/runner/work/hive-ui/hive-ui/pages/trust/[slug].vue?macro=true";
import { default as _91contact_id_93U4BnBuoqqpMeta } from "/home/runner/work/hive-ui/hive-ui/pages/trust/contact/[contact_id].vue?macro=true";
import { default as indexlqV3kJi4VGMeta } from "/home/runner/work/hive-ui/hive-ui/pages/trust/index.vue?macro=true";
export default [
  {
    name: "auth-callback",
    path: "/main/auth/callback",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "Sign In",
    path: "/main/auth/sign-in",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "main-business-slug",
    path: "/main/business/:slug()",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/business/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "main-comps",
    path: "/main/comps",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/comps.vue").then(m => m.default || m)
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/index.vue").then(m => m.default || m)
  },
  {
    name: "Active Exposures",
    path: "/main/personal/active-exposures",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/active-exposures.vue").then(m => m.default || m)
  },
  {
    name: "Dashboard",
    path: "/main/personal/dashboard",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "Excruciating Detail",
    path: "/main/personal/excruciating-detail",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/excruciating-detail.vue").then(m => m.default || m)
  },
  {
    name: "Gmail Inbox Scan",
    path: "/main/personal/inbox-scan",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/inbox-scan.vue").then(m => m.default || m)
  },
  {
    name: "Manage Account",
    path: "/main/personal/manage-account",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/manage-account.vue").then(m => m.default || m)
  },
  {
    name: "Private Search",
    path: "/main/personal/private-search",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/private-search.vue").then(m => m.default || m)
  },
  {
    name: "Support",
    path: "/main/personal/support",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/support.vue").then(m => m.default || m)
  },
  {
    name: "Manage Company",
    path: "/trust/:slug()",
    meta: _91slug_93n2ApAA9AHyMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/trust/[slug].vue").then(m => m.default || m)
  },
  {
    name: "Contacts",
    path: "/trust/contact/:contact_id()",
    meta: _91contact_id_93U4BnBuoqqpMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/trust/contact/[contact_id].vue").then(m => m.default || m)
  },
  {
    name: "Trust & Family",
    path: "/trust",
    meta: indexlqV3kJi4VGMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/trust/index.vue").then(m => m.default || m)
  }
]