export default {
    root: ({ props, context, parent, instance }) => ({
        class: [
            {
                'p-btn-root_disabled': context.disabled,
                'p-btn-root_primary': instance.$attrs.variant == 'primary' || !instance.$attrs.variant,
                'p-btn-root_secondary': instance.$attrs.variant == 'secondary',
                'p-btn-root_tertiary': instance.$attrs.variant == 'tertiary',
                'p-btn-root_link': instance.$attrs.variant == 'link',
            },
        ],
        watch: {},
    }),
    label: ({ props }) => ({}),
    icon: ({ props }) => ({
        class: ['loading-spinner'],
    }),
    loadingIcon: ({ props }) => ({
        class: ['animate-spin'],
    }),
    badge: ({ props }) => ({
        class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }],
    }),
};
