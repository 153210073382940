<template>
    <div
        class="flex flex-col justify-center items-center h-full gap-4"
        style="height: calc(100vh - var(--size-header-height) - var(--size-footer-height))"
    >
        <h1>You are being redirected...</h1>
        <p>Let's buzz you over to the login page</p>
        <img src="/assets/orwell/happy.png" />
    </div>
</template>

<script setup>
const auth = useAuthStore();

onMounted(() => {
    auth.signIn();
});

definePageMeta({ name: 'Sign In' });
</script>

<style lang="scss" scoped></style>
